import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import { messages, loadedLanguages } from '@assets/lang';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'pl',
    fallbackLocale: 'pl',
    messages
});

const defaults = 'pl';

function setI18nLanguage(lng) {
    i18n.locale = lng;
    axios.defaults.headers.common['Accept-Language'] = lng;
    document.querySelector('html').setAttribute('lang', lng);
    return lng;
}

export function loadLanguageAsync(lang) {
    let lng = null;

    if (lang === null || lang === undefined) {
        lng = defaults;
    } else {
        lng = lang.slice(0, 2).toLowerCase();

        if (!loadedLanguages.includes(lng)) {
            lng = defaults;
        }
    }

    // If the same language
    if (i18n.locale === lng) {
        return Promise.resolve(setI18nLanguage(lng));
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lng)) {
        return Promise.resolve(setI18nLanguage(lng));
    }
}
