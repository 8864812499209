// Register each file as a corresponding Vuex module. Module nesting
// will mirror [sub-]directory hierarchy and modules are namespaced
// as the camelCase equivalent of their file name.

const messages = {};
const loadedLanguages = [];

(function updateModules() {
    const requireModule = require.context(
        // Search for files in the current directory.
        '.',
        // Search for files in subdirectories.
        true,
        // Include any .js files that are not this file or a unit test.
        /^((?!index|\.unit\.).)*\.json$/
    );

    requireModule.keys().forEach(fileName => {
        const moduleDefinition = requireModule(fileName);

        const modulePath = fileName
            // Remove the "./" from the beginning.
            .replace(/^\.\//, '')
            // Remove the file extension from the end.
            .replace(/\.\w+$/, '');

        loadedLanguages.push(modulePath);
        messages[modulePath] = moduleDefinition;
    });
})();

export { loadedLanguages, messages };
