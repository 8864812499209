require('dotenv');

module.exports = {
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
    frontendUrl: process.env.VUE_APP_FRONTEND_URL,
    socketUrl: process.env.VUE_APP_SOCKET_URL,
    mediaBaseUrl: process.env.VUE_APP_MEDIA_BASE_URL,
    geoFlotea: process.env.VUE_APP_GEO_FLOTEA,
    flotea: 'https://flotea.pl:8011/api',
    publicPath: '/',
    googleAnalyticsDefaultId:
        process.env.NODE_ENV === 'production' ? 'G-56PXZLHVML' : '',
    googleAnalyticsId:
        process.env.NODE_ENV === 'production'
            ? process.env.VUE_APP_GOOGLE_ANALYTICS_ID
            : ''
};
